import React, { Component } from 'react';

export default function Thanks()  {
    
        return (
            <div className="Auth-container">
                Bye Bye!
            </div>
        );
    
}
