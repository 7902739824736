
import React, { Component } from "react";
import react,{ useState } from "react"
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

 export default function Simreplacement(){
    const agentNumber = localStorage.getItem("AgentNumber")
    const [initialBlock,setinitialBlock]=useState(true)
    const [msisdn,setmsisdn]= useState('')

    function proceed(){
        localStorage.setItem('lostMSISDN',msisdn)
        const req = Axios.get('https://ekyc.chili.mu:9443/api/sim/lost/damage/msisdn/230'+msisdn,
        {
            headers: { 'Content-Type': 'application/json' }
        }).then((res)=>{
            console.log(res.data.data.token)
            let tkn = res.data.data.token
            alert(`Complaint has been already Registered for this MSISDN with Reference Number ${tkn}`)

        }).catch((error)=>{
            console.group(error.response.data.httpCode)
            let x = error.response.data.httpCode
            if(x == 404){
                window.location.replace("/memo")
            }
            else{
            alert("Please try after SomeTime")
            }

        })
    }
    return(
            <div className="Auth-container" style={{textAlign:'center'}}>
                {
                    agentNumber==null || agentNumber.trim().length==0?
                    null
                    :<div  style={{marginTop:-50,textAlign:'center'}}>
                        <label><strong>Agent Number:{agentNumber}</strong></label>
                        <Link to="/logout" style={{textAlign:'end',marginLeft:20,color:'black'}}>Logout</Link>
                        <br></br>
                    </div>
                }

                {initialBlock==true ?
                <div>
                <br></br>
                <br></br>
                <button className="button" style={{ fontSize: 16,width: 150, padding:10}} onClick={(e)=>{
                localStorage.setItem('issueType','lost')
                setinitialBlock(false)}}>SIM Lost / Stolen</button>
                <br></br>
                <br></br>
                <button className="button" style={{ fontSize: 16,width: 150, padding:10}}onClick={(e)=>{
                localStorage.setItem('issueType','damage')
                setinitialBlock(false)}}>SIM Damaged</button>
                    </div>
                :<div>
                    <br></br>
                    <br></br>
                    <TextField
                            name="MSISDN"
                            type="tel"
                            id="outlined-required"
                            label={<div style={{color:'black'}}>MSISDN</div>}
                            variant="outlined"
                            inputProps={{maxLength:8}}
                            onChange={(e)=>{setmsisdn(e.target.value)
                                }}/>
                            <br></br>
                            <br></br>
                            { msisdn.trim().length == 8 ?
                                <button className="button" style={{ fontSize: 16,width: 150, padding:10}}
                                      onClick={proceed}> Submit </button>
                                :null
                            }
                            
                    </div>}
                
                
            </div>
    )
}